import React, { FC } from 'react';
import { Box, Container, Stack, Typography, useTheme, Link } from '@mui/material';
import { EmailOutlined, RoomOutlined } from '@mui/icons-material';
import { isMobile } from 'react-device-detect';
import { AppRoutes } from '../../utils/routes.const';
import { useNavigate } from 'react-router-dom';

interface FooterProps { }

const Footer: FC<FooterProps> = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <Box
      component="footer"
      sx={{
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(2),
        boxShadow: '0 -2px 5px rgba(0,0,0,0.1)',
        bottom: 0,
        position: 'relative'
      }}
    >
      <Container>
        <Stack direction={isMobile ? 'column' : 'row'} justifyContent="space-between" alignItems="center">

          <Stack direction="column" spacing={1} alignItems={'center'}>
            <Typography variant="body2" color="textSecondary" style={{ display: 'flex', alignItems: 'center' }}>
              <EmailOutlined style={{ marginRight: 5 }} /> <a href="mailto:kontakt@claimflight.pl" style={{ textDecoration: 'none', color: theme.palette.primary.main }}>kontakt@claimflight.pl</a>
            </Typography>
            <Typography variant="body2" color="textSecondary" style={{ display: 'flex', alignItems: 'center' }}>
              <RoomOutlined style={{ marginRight: 5 }} /> Address: 1234 Street Name, City, Country
            </Typography>
          </Stack>
          <Typography variant="body2" color="textSecondary">
            &copy; {new Date().getFullYear()} ClaimFlight.pl All rights reserved.
          </Typography>
          <Stack style={{ color: theme.palette.primary.main }} spacing={2}>
            <Link sx={{ "&:hover": { cursor: 'pointer' }, textDecoration: 'none' }} onClick={() => navigate(AppRoutes.Claim)} color="inherit">Formularz zgłoszeniowy</Link>
            <Link sx={{ "&:hover": { cursor: 'pointer' }, textDecoration: 'none' }} onClick={() => navigate(AppRoutes.Terms)} color="inherit">Regulamin</Link>
            <Link sx={{ "&:hover": { cursor: 'pointer' }, textDecoration: 'none' }} onClick={() => navigate(AppRoutes.Privacy)} color="inherit">Polityka prywatności</Link>
            <Link sx={{ "&:hover": { cursor: 'pointer' }, textDecoration: 'none' }} onClick={() => navigate(AppRoutes.Contact)} color="inherit">Contact</Link>
          </Stack>
        </Stack>
      </Container>
    </Box>
  );
};

export default Footer;
