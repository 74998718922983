import React, { FC, useEffect, useRef, useState } from 'react';
import styles from '../FlightInformationPart/FlightInformationPart.module.scss';
import { Box, Autocomplete, TextField, Grid, InputLabel, Stack, Checkbox, FormControlLabel, Alert, FormControl, Select, Button, InputAdornment } from '@mui/material';
import axios from 'axios'; // Import the 'axios' library
import { Label, Check, AccountCircle, FlightTakeoff, FlightLand } from '@mui/icons-material';
import { DatePicker, DateTimePicker } from '@mui/x-date-pickers';
import { Controller, FormProvider, set, useForm } from 'react-hook-form';
import dayjs, { Dayjs } from 'dayjs';
import { isMobile } from 'react-device-detect';



interface Dictionary<T> {
  [Key: string]: T;
}

interface FlightInformationPartProps {
  onSubmit: (data: any) => void,
  handleNext(): void,
  handleBack(): void,
  activeStep: number,
  prefilledValues: { departure: string, arrival: string },
  steps: string[],
}

interface FlightInformation {
  departure: string;
  arrival: string;
  flightNumber: string;
  euCountry: boolean;
  plannedArrival: Dayjs | null;
  actualArrival: Dayjs | null;
}



const FlightInformationPart: FC<FlightInformationPartProps> = ({ handleNext, handleBack, activeStep, steps, prefilledValues }) => {
  const [compensation, setCompensation] = React.useState(null);
  const [differenceCalculated, setDifferenceCalculated] = React.useState(0);
  const [departureOptions, setDepartureOptions] = useState<any[]>([]);
  const [arrivalOptions, setArrivalOptions] = useState<any[]>([]);
  const [isDataPrefilled, setIsDataPrefilled] = useState(false);


  const { control, trigger, getValues, formState: { errors }, watch, setValue } = useForm<FlightInformation>({
    defaultValues: {
      departure: prefilledValues.departure || '',
      arrival: prefilledValues.arrival || '',
      flightNumber: '',
      euCountry: false,
      plannedArrival: null,
      actualArrival: null,
    }

  });
  const watchTimes = watch(["plannedArrival", "actualArrival"]);
  const watchFlights = watch(["departure", "arrival"]);
  const watchRequiredForRecompensation = watch(["departure", "arrival", "euCountry", "plannedArrival", "actualArrival",]);


  // useEffect(() => {
  //   console.log(departure, arrival);
  //   if (!departure && !arrival) { // TODO: fix this condition
  //     setIsDataPrefilled(true);
  //   }
  // }, [])


  useEffect(() => {
    if (watchRequiredForRecompensation[0] && watchRequiredForRecompensation[1] && watchRequiredForRecompensation[3] && watchRequiredForRecompensation[4]) {

      const url = `${process.env.REACT_APP_BACKEND_URL}/Calculate/compensation?airportCodeFrom=${watchRequiredForRecompensation[0]}&airportCodeTo=${watchRequiredForRecompensation[1]}&euCountry=${watchRequiredForRecompensation[2]}&plannedArrivalDate=${watchRequiredForRecompensation[3].format('YYYY-MM-DD HH:mm')}&actualArrivalDate=${watchRequiredForRecompensation[4].format('YYYY-MM-DD HH:mm')}`;

      axios
        .get(url)
        .then(function (response: any) {
          const { status, data } = response;
          if (status === 200) {
            setCompensation(data);
            console.log(data);
          }
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        });
    }
  }, [watchRequiredForRecompensation])

  useEffect(() => {
    if (watchTimes[1] && watchTimes[0] && watchTimes[1] > watchTimes[0]) { // TODO: fix this condition
      setDifferenceCalculated(watchTimes[1].diff(watchTimes[0], 'minutes'));
    }
  }, [watchTimes])

  const handleValidation = async () => {
    const isValid = await trigger(["departure", "arrival", "flightNumber", "plannedArrival", "actualArrival"])
    if (isValid) {
      const values = getValues()
      console.log(values)
      onSubmit();
      handleNext();
    } else {
      console.log("aaaa, chcialoby sie: ", errors)
    }
  }

  useEffect(() => {
    axios.all([axios.get(`${process.env.REACT_APP_BACKEND_URL}/Airport/iata/${prefilledValues.arrival}`), axios.get(`${process.env.REACT_APP_BACKEND_URL}/Airport/iata/${prefilledValues.departure}`)])
      .then(axios.spread((arrivalResponse: any, departureResponse: any) => {
        setArrivalOptions(arrivalResponse.data);
        setValue('arrival', arrivalResponse.data.find((option: any) => option.iata_code === prefilledValues.arrival)?.iata_code);
        setDepartureOptions(departureResponse.data);
        setValue('departure', arrivalResponse.data.find((option: any) => option.iata_code === prefilledValues.departure)?.iata_code);
      }))
      .finally(() => setIsDataPrefilled(true));

  }, [prefilledValues]);


  const onSubmit = () => {
    localStorage.setItem('flightData', JSON.stringify(getValues()));
  };

  const formatDelayTimeInPolish = (delayInMinutes: number): string => {
    const days = Math.floor(delayInMinutes / (24 * 60));
    const hours = Math.floor((delayInMinutes % (24 * 60)) / 60);
    const minutes = delayInMinutes % 60;

    let result = '';

    if (days > 0) {
      result += `${days} ${days === 1 ? 'dzień' : 'dni'}`;
    }

    if (hours > 0) {
      if (result) result += ', ';
      result += `${hours} ${hours === 1 ? 'godzina' : (hours < 5 ? 'godziny' : 'godzin')}`;
    }

    if (minutes > 0) {
      if (result) result += ', ';
      result += `${minutes} ${minutes === 1 ? 'minuta' : (minutes < 5 ? 'minuty' : 'minut')}`;
    }

    return result || '0 minut';
  }

  const handleAutocompleteInputChange = async (value: string, name: string) => {
    const url = `${process.env.REACT_APP_BACKEND_URL}/Airport/${value}`;
    await axios
      .get(url)
      .then(function (response: any) {
        const { status, data } = response;
        if (status === 200) {
          switch (name) {
            case 'departure':
              setValue('departure', value)
              setDepartureOptions(data);
              break;
            case 'arrival':
              setValue('arrival', value)
              setArrivalOptions(data);
              break;
          }
        }
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
  };

  return (
    <Stack width={'80%'}>

      <Stack marginBottom={3}>
        <InputLabel id="demo-simple-select-label">Wylot</InputLabel>
        <Controller
          name='departure'
          control={control}
          rules={{
            required: "Lotnisko jest wymagane",
          }}
          defaultValue={prefilledValues.departure}
          render={({ field: { onChange, value, name } }) => {
            return (
              <Autocomplete
                id="departure"
                options={departureOptions ?? []}
                getOptionLabel={(option: any) => `${option.airport_name} (${option.iata_code})`}
                value={departureOptions?.find((option: any) => value === option.iata_code)}
                onChange={(e: any, value: any) => {
                  if (value == null) setDepartureOptions([]);
                  onChange(value ? value.iata_code : null);
                }
                }
                renderInput={params => (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder="Wpisz aby wyszukać lotnisko"
                    // slotProps={{
                    //   input: {
                    //     startAdornment: (
                    //       <InputAdornment position="start">
                    //         <FlightTakeoff />
                    //       </InputAdornment>
                    //     ),
                    //   },
                    // }}
                    error={!!errors.departure}
                    helperText={errors.departure ? errors.departure.message : ""}
                    onChange={e => handleAutocompleteInputChange(e.target.value, name)}
                    onEmptied={_ => handleAutocompleteInputChange('', name)}
                  />
                )}
              />
            );
          }}
        />
      </Stack>

      <Stack marginBottom={3}>
        <InputLabel id="demo-simple-select-label">Przylot</InputLabel>
        <Controller
          name='arrival'
          control={control}
          rules={{
            required: "Lotnisko jest wymagane",
            validate: (value) => {
              if (value == getValues('departure')) return "Nie możesz przylecieć na to samo lotnisko"
              return true
            }
          }}
          defaultValue={prefilledValues.arrival}
          render={({ field: { onChange, value, name } }) => {
            return (
              <Autocomplete
                id="arrival"
                options={arrivalOptions ?? []}
                getOptionLabel={(option: any) => `${option.airport_name} (${option.iata_code})`}
                value={arrivalOptions?.find((option: any) => value === option.iata_code)}
                onChange={(e: any, value: any) => {
                  if (value == null) setArrivalOptions([]);
                  onChange(value ? value.iata_code : null);
                }
                }
                renderInput={params => (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder="Wpisz aby wyszukać lotnisko"
                    // slotProps={{
                    //   input: {
                    //     startAdornment: (
                    //       <InputAdornment position="start">
                    //         <FlightLand />
                    //       </InputAdornment>
                    //     ),
                    //   },
                    // }}
                    error={!!errors.arrival}
                    helperText={errors.arrival ? errors.arrival.message : ""}
                    onChange={e => handleAutocompleteInputChange(e.target.value, name)}
                    onEmptied={_ => handleAutocompleteInputChange('', name)}
                  />
                )}
              />
            );
          }}
        />
      </Stack>

      <Stack marginBottom={3}>
        <InputLabel id="demo-simple-select-label">Podaj numer lotu</InputLabel>
        <Controller
          name='flightNumber'
          rules={{
            required: "Numer lotu jest wymagany",
            minLength: { value: 2, message: "Numer lotu jest za krótki" },
            maxLength: { value: 8, message: "Numer lotu jest za długi" }
          }}
          control={control}
          render={({ field: { onChange, value, name } }) => {
            return (
              <TextField
                variant="outlined"
                value={value}
                onChange={onChange}
                error={!!errors.flightNumber}
                helperText={errors.flightNumber ? errors.flightNumber.message : ""}
              />
            );
          }}
        />
      </Stack>

      <Stack marginBottom={3}>
        <FormControlLabel
          control={<Controller
            name='euCountry'
            control={control}
            render={({ field: { onChange, value } }) => {
              return (
                <Checkbox
                  checked={value}
                  onChange={onChange}
                />
              );
            }}
          />} label="Podróż miała początek lub koniec w kraju należącym do UE" />
      </Stack>

      <Stack marginBottom={3}>
        <InputLabel id="demo-simple-select-label">Data i czas planowanego przylotu do celu</InputLabel>
        <Controller
          name='plannedArrival'
          control={control}
          rules={{
            required: "Data planowanego przylotu jest wymagana",
            validate: (value) => {
              if (!value) return "Data planowanego przylotu jest wymagana";
              if (dayjs(value).isAfter(dayjs())) return "Data musi być w przeszłości";

              return true;
            },
          }}
          render={({ field: { onChange, value } }) => (
            <DateTimePicker value={value} onChange={onChange}
              slotProps={{
                textField: {
                  placeholder: 'Uzupełnij datę i godzinę',
                  error: !!errors.plannedArrival,
                  helperText: errors.plannedArrival ? errors.plannedArrival.message : ""
                }
              }} />
          )}
        />
      </Stack>

      <Stack marginBottom={3}>
        <InputLabel id="demo-simple-select-label">Data i czas rzeczywistego przylotu do celu</InputLabel>
        <Controller
          name='actualArrival'
          control={control}
          rules={{
            required: "Data rzeczywistego przylotu jest wymagana",
            validate: (value) => {
              if (!value) return "Data planowanego przylotu jest wymagana";
              if (dayjs(value).isAfter(dayjs())) return "Data musi być w przeszłości";
              if (dayjs(value).isBefore(getValues('plannedArrival'))) {
                return "Rzeczywisty przylot nie może być wcześniejszy niż planowany przylot";
              }
              return true;
            },
          }}
          render={({ field: { onChange, value } }) => (
            <DateTimePicker value={value != dayjs(0) ? value : null} onChange={onChange} slotProps={{
              textField: {
                placeholder: 'Uzupełnij datę i godzinę',
                error: !!errors.actualArrival,
                helperText: errors.actualArrival ? errors.actualArrival.message : ""
              }
            }} />
          )}
        />
      </Stack>
      {differenceCalculated != 0 && (<Stack style={{ marginBottom: 20 }}>
        <Alert icon={<Check fontSize="inherit" />} severity="success">
          Przylot opóźniony o {formatDelayTimeInPolish(differenceCalculated)}
        </Alert>
      </Stack>)}



      {compensation != null && compensation != 0 && (<Stack style={{ marginBottom: 20 }}>
        <Alert icon={<Check fontSize="inherit" />} severity="success">
          Przysługuje Ci {compensation}€ rekompensaty
        </Alert>
      </Stack>)}

      <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
        <Button
          variant="contained"
          color="inherit"
          disabled={activeStep === 0}
          onClick={handleBack}
          sx={{ mr: 1 }}
        >
          Back
        </Button>
        <Box sx={{ flex: '1 1 auto' }} />
        <Button type='submit'
          variant="contained" onClick={() => {
            handleValidation()
          }}>
          {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
        </Button>
      </Box>
    </Stack>

  );
};


export default FlightInformationPart;
