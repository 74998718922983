import React, { FC } from 'react';
import { Box, Card, Container, Stack, Typography, useTheme, Link } from '@mui/material';
import { CheckCircle, AirplaneTicketOutlined, LightbulbOutlined, SavingsOutlined } from '@mui/icons-material';
import styles from './AboutUs.module.scss';
import { isMobile } from 'react-device-detect';
import { AppRoutes } from '../../utils/routes.const';

interface AboutUsProps { }

const AboutUs: FC<AboutUsProps> = () => {
  const theme = useTheme();

  return (
    <Container>
      <Stack direction={isMobile ? 'column' : 'row'} spacing={5} marginTop={5}>
        <Box sx={{ flex: 1 }}>
          <Typography variant="h4" marginBottom={3}>O nas</Typography>
          <Typography variant="body1" paragraph>
            ClaimFlight to firma specjalizująca się w uzyskiwaniuodszkodowań za opóźnione i odwołane loty. Nasz zespół ekspertów posiada wieloletnie doświadczenie w branży lotniczej i prawnej, co pozwala nam skutecznie walczyć o prawa pasażerów. Naszym celem jest zapewnienie, że każdy podróżny otrzyma należne mu odszkodowanie bez zbędnych formalności i stresu.
          </Typography>
          <Typography variant="body1" paragraph>
            Wystarczy kilka kliknięć, aby rozpocząć proces ubiegania się o odszkodowanie. Nasz prosty  <Link href={AppRoutes.Claim}>formularz online</Link> pozwala na szybkie i łatwe zgłoszenie roszczenia. Po przesłaniu danych, nasi specjaliści zajmą się resztą, dbając o każdy szczegół sprawy.
          </Typography>
          <Typography variant="body1" paragraph>
            Nie pobieramy żadnych opłat, jeśli nie uda nam się uzyskać odszkodowania. Nasza prowizja jest naliczana tylko w przypadku sukcesu, co motywuje nas do maksymalnego zaangażowania w każdą sprawę.
          </Typography>
        </Box>
        <Box sx={{ flex: 1 }}>
          <Stack spacing={4}>
            {/* <Typography variant="h5" style={{ display: 'flex', alignItems: 'center' }}>
              <CheckCircle color='success' style={{ marginRight: 10 }} /> Wystarczy kilka kliknięć, by rozpocząć swoją sprawę.
            </Typography>
            <Typography variant="h5" style={{ display: 'flex', alignItems: 'center' }}>
              <CheckCircle color='success' style={{ marginRight: 10 }} /> Nie pobieramy opłat, jeśli nie wywalczymy dla Ciebie odszkodowania.
            </Typography>
            <Typography variant="h5" style={{ display: 'flex', alignItems: 'center' }}>
              <CheckCircle color='success' style={{ marginRight: 10 }} /> Nasi eksperci znają przepisy, które pomogą Ci odzyskać nawet do 600 € za lot!
            </Typography>
            <AirplaneTicketOutlined style={{ fontSize: 80, color: theme.palette.primary.main }} />
            <LightbulbOutlined style={{ fontSize: 80, color: theme.palette.primary.main }} />
            <SavingsOutlined style={{ fontSize: 80, color: theme.palette.primary.main }} /> */}
          </Stack>
        </Box>
      </Stack>
    </Container>
  );
};

export default AboutUs;
