import React, { FC } from 'react';
import styles from './HomePage.module.scss';
import { Card, Container, Stack, Typography, useTheme } from '@mui/material';
import { Helmet } from 'react-helmet';
import PeopleStanding from '../../data/images/people_waiting.png';
import { isMobile } from 'react-device-detect';
import CalculateRecompensate from '../CalculateRecompensate/CalculateRecompensate';
import CardContent from '@mui/material/CardContent';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { AirplaneTicket, AirplaneTicketOutlined, Lightbulb, LightbulbOutlined, SavingsOutlined } from '@mui/icons-material';
import logo from '../../data/images/claim-flight-new-logo-text.png'

interface HomePageProps { }

const HomePage: FC<HomePageProps> = () => {

  const theme = useTheme();

  return (
    <>
      <Helmet>
        <title>ClaimFlight - Zwrot za opóźniony lot</title>
        <meta name="description" content="ClaimFlight - zwrot za opóźniony lot, uzyskaj rekompensate za opóźniony lub odwołany lot" />
        <meta name="keywords" content="zwrot,lot,rekompensata,opóźniony,odwołany,podróż,samolot" />
        <meta property="og:title" content="ClaimFlight - zwrot za opóźniony lot" />
        <meta property="og:description" content="Zwrot za opóźniony lot, uzyskaj rekompensate za opóźniony lub odwołany lot" />
        <meta property="og:image" content={logo} />
        <meta property="og:url" content="https://claimflight.pl" />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content={logo} />
        <meta name="twitter:title" content="ClaimFlight - zwrot za opóźniony lot" />
        <meta name="twitter:description" content="Zwrot za opóźniony lot, uzyskaj rekompensate za opóźniony lub odwołany lot" />
        <meta name="twitter:image" content={logo} />
      </Helmet>

      <Container style={{
        paddingTop: isMobile ? 20 : 80,
        paddingBottom: isMobile ? 20 : 80,
        paddingLeft: isMobile ? 0 : 120,
        paddingRight: isMobile ? 0 : 120,
        maxWidth: '100%',
        marginBottom: '100px',
        background: `linear-gradient(
          rgba(255, 255, 255, 0.1), 
          rgba(255, 255, 255, 0.1)
        ), url(${PeopleStanding})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        height: isMobile ? '' : '100vh',
      }}>
        <Stack>
          <Stack alignItems='center' style={{ padding: isMobile ? 20 : '' }}>
            <Typography variant="h2" marginBottom={2} fontWeight='400' color={'white'} >Opóźniony lot?</Typography>
            <Typography variant={isMobile ? 'h5' : 'h4'} fontWeight='400' marginBottom={isMobile ? 2 : 10} color={'white'}>Uzyskaj odszkodowanie za opóźniony lot. Podaj dane, a my zajmiemy się resztą</Typography>

          </Stack>
          <Stack direction={isMobile ? 'column' : 'row'} spacing={2} alignItems='center'>
            <Stack alignItems={'center'} sx={{ flexGrow: 1 }} >
              <CalculateRecompensate wrapperStyle={{ backgroundColor: 'rgba(255, 255, 255, 0.9)', borderRadius: '10px', padding: isMobile ? 20 : 50 }} showTitle={false}></CalculateRecompensate>
            </Stack>
            <Stack sx={{ maxWidth: isMobile ? '90%' : '50%', flexGrow: 1 }} >
              <Stack style={{ backgroundColor: 'rgba(255, 255, 255, 0.9)', marginLeft: 5, marginRight: 5, borderRadius: '10px', padding: isMobile ? 20 : 55 }} spacing={4}>

                <Typography variant="h5" style={{ display: 'flex', alignItems: 'center' }}> <CheckCircleIcon color='success' style={{ marginRight: 10 }} />  Wystarczy kilka kliknięć, by rozpocząć swoją sprawę.</Typography>

                <Typography variant="h5" style={{ display: 'flex', alignItems: 'center' }} > <CheckCircleIcon color='success' style={{ marginRight: 10 }} /> Nie pobieramy opłat, jeśli nie wywalczymy dla Ciebie odszkodowania.</Typography>

                <Typography variant="h5" style={{ display: 'flex', alignItems: 'center' }}> <CheckCircleIcon color='success' style={{ marginRight: 10 }} /> Nasi eksperci znają przepisy, które pomogą Ci odzyskać nawet do 600 € za lot!</Typography>

              </Stack>


            </Stack>
          </Stack>

        </Stack>
      </Container >

      <Container style={{ marginBottom: 100 }}>
        <Stack direction={isMobile ? 'column' : 'row'} spacing={5}>
          <Card style={{ width: isMobile ? '100%' : '28%', textAlign: 'center' }} >
            <CardContent>
              <AirplaneTicketOutlined style={{ fontSize: 80, color: theme.palette.primary.main }} />
              <Typography variant="h4" marginBottom={3}>Zgłoś swój lot</Typography>
              <Typography variant="body1">Podaj podstawowe informacje o swoim opóźnionym lub odwołanym locie w naszym prostym formularzu.</Typography>
            </CardContent>
          </Card>
          <Card style={{ width: isMobile ? '100%' : '28%', textAlign: 'center' }}>
            <CardContent>
              <LightbulbOutlined style={{ fontSize: 80, color: theme.palette.primary.main }} />
              <Typography variant="h4">Analizujemy Twój przypadek</Typography>
              <Typography variant="body1">Zweryfikujemy, czy kwalifikujesz się do odszkodowania, i zajmiemy się formalnościami.</Typography>
            </CardContent>
          </Card>
          <Card style={{ width: isMobile ? '100%' : '28%', textAlign: 'center' }}>
            <CardContent>
              <SavingsOutlined style={{ fontSize: 80, color: theme.palette.primary.main }} />
              <Typography variant="h4">Otrzymujesz pieniądze</Typography>
              <Typography variant="body1">Po pozytywnym rozpatrzeniu sprawy przelewamy środki bezpośrednio na Twoje konto.            </Typography>
            </CardContent>
          </Card>
        </Stack>
      </Container>



      <Container>
        <Stack direction={isMobile ? 'column' : 'row'} spacing={5}>
          <Card style={{ width: isMobile ? '100%' : '28%', textAlign: 'center' }} >
            <CardContent>
              <Typography variant="h4" marginBottom={3}>Zgłoś swój lot</Typography>
              <Typography variant="body1">Podaj podstawowe informacje o swoim opóźnionym lub odwołanym locie w naszym prostym formularzu.</Typography>
            </CardContent>
          </Card>
          <Card style={{ width: isMobile ? '100%' : '28%', textAlign: 'center' }}>
            <CardContent>
              <Typography variant="h4">Analizujemy Twój przypadek</Typography>
              <Typography variant="body1">Zweryfikujemy, czy kwalifikujesz się do odszkodowania, i zajmiemy się formalnościami.</Typography>
            </CardContent>
          </Card>
          <Card style={{ width: isMobile ? '100%' : '28%', textAlign: 'center' }}>
            <CardContent>
              <Typography variant="h4">Otrzymujesz pieniądze</Typography>
              <Typography variant="body1">Po pozytywnym rozpatrzeniu sprawy przelewamy środki bezpośrednio na Twoje konto.            </Typography>
            </CardContent>
          </Card>
        </Stack>
      </Container>

    </>
  )
};

export default HomePage;
