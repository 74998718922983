import React, { FC, useState } from 'react';
import { Box, Button, Container, InputLabel, Stack, TextField, Typography } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { EmailOutlined, RoomOutlined, PhoneOutlined } from '@mui/icons-material';
import { isMobile } from 'react-device-detect';
import PeopleStanding from '../../data/images/people_waiting.png';

interface ContactForm {
    name: string;
    email: string;
    message: string;
}

const ContactPage: FC = () => {
    const { control, handleSubmit, formState: { errors } } = useForm<ContactForm>({
        defaultValues: {
            name: '',
            email: '',
            message: '',
        },
    });

    const messageLengthLimit = 500;

    const [messageLength, setMessageLength] = useState(0);

    const onSubmit = (data: ContactForm) => {
        console.log(data);
        // Handle form submission
    };

    return (
        <>
            <Stack style={{
                height: 200,
                background: `linear-gradient(
              rgba(255, 255, 255, 0.1), 
              rgba(255, 255, 255, 0.1)
            ), url(${PeopleStanding})`,
            }}>

            </Stack>
            <Container>

                <Stack direction={isMobile ? 'column' : 'row'} spacing={5} marginTop={5}>
                    <Box sx={{ flex: 1 }}>
                        <Typography variant="h4" marginBottom={3}>Skontaktuj się z nami</Typography>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <Stack spacing={3}>
                                <Stack>
                                    <InputLabel>Imie i nazwisko</InputLabel>
                                    <Controller
                                        name="name"
                                        control={control}
                                        rules={{ required: "Name is required" }}
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                variant="outlined"
                                                error={!!errors.name}
                                                helperText={errors.name ? errors.name.message : ""}
                                            />
                                        )}
                                    />
                                </Stack>
                                <Stack>
                                    <InputLabel>Email</InputLabel>
                                    <Controller
                                        name="email"
                                        control={control}
                                        rules={{
                                            required: "Email is required",
                                            pattern: {
                                                value: /\S+@\S+\.\S+/,
                                                message: "Invalid email format",
                                            },
                                        }}
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                variant="outlined"
                                                error={!!errors.email}
                                                helperText={errors.email ? errors.email.message : ""}
                                            />
                                        )}
                                    />
                                </Stack>
                                <Stack>
                                    <InputLabel>Wiadomość</InputLabel>
                                    <Controller
                                        name="message"
                                        control={control}
                                        rules={{
                                            required: "Message is required",
                                            maxLength: {
                                                value: messageLengthLimit,
                                                message: `Message cannot exceed ${messageLengthLimit} characters`,
                                            },
                                        }}
                                        render={({ field }) => (
                                            <>
                                                <TextField
                                                    {...field}
                                                    variant="outlined"
                                                    multiline
                                                    rows={4}
                                                    error={!!errors.message}
                                                    helperText={errors.message ? errors.message.message : ""}
                                                    onChange={(e) => {
                                                        field.onChange(e);
                                                        setMessageLength(e.target.value.length);
                                                    }}
                                                />
                                                <Typography
                                                    variant="caption"
                                                    display="block"
                                                    align="right"
                                                    color={messageLength > messageLengthLimit ? 'red' : 'inherit'}
                                                >
                                                    {messageLength}/{messageLengthLimit}
                                                </Typography>
                                            </>
                                        )}
                                    />
                                </Stack>
                                <Button type="submit" variant="contained">Send</Button>
                            </Stack>
                        </form>
                    </Box>
                    <Box sx={{ flex: 1 }}>
                        <Typography variant="h4" marginBottom={3}>Kontakt</Typography>
                        <Stack spacing={2}>
                            <Typography variant="body1" style={{ display: 'flex', alignItems: 'center' }}>
                                <EmailOutlined style={{ marginRight: 5 }} /> kontakt@claimflight.pl
                            </Typography>
                            <Typography variant="body1" style={{ display: 'flex', alignItems: 'center' }}>
                                <RoomOutlined style={{ marginRight: 5 }} /> 1234 Street Name, City, Country
                            </Typography>
                            <Typography variant="body1" style={{ display: 'flex', alignItems: 'center' }}>
                                <PhoneOutlined style={{ marginRight: 5 }} /> +48 123 456 789
                            </Typography>
                        </Stack>
                    </Box>
                </Stack>
            </Container>
        </>
    );
};

export default ContactPage;
