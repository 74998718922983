import React, { FC, useState } from 'react';
import { Box, Fab, Modal, Typography, Accordion, AccordionSummary, AccordionDetails, Stack, IconButton } from '@mui/material';
import CookieIcon from '@mui/icons-material/Cookie';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CloseIcon from '@mui/icons-material/Close';
import { isMobile } from 'react-device-detect';

interface CookieCategory {
    category: string;
    required: boolean;
    description: string;
}

const cookieCategories: CookieCategory[] = [
    {
        category: "Niezbędne",
        required: true,
        description: "Te pliki cookie są niezbędne do prawidłowego funkcjonowania strony i nie mogą być wyłączone w naszych systemach.",
    },
    {
        category: "Analityczne",
        required: false,
        description: "Te pliki cookie pozwalają nam liczyć wizyty i źródła ruchu, dzięki czemu możemy mierzyć i poprawiać wydajność naszej strony.",
    },
    {
        category: "Reklamowe",
        required: false,
        description: "Te pliki cookie mogą być ustawione przez naszych partnerów reklamowych za pośrednictwem naszej strony.",
    },
];

const Cookies: FC = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleOpen = () => setIsModalOpen(true);
    const handleClose = () => setIsModalOpen(false);

    return (
        <>
            <Fab
                color="primary"
                aria-label="cookies"
                onClick={handleOpen}
                sx={{ position: 'fixed', bottom: 16, left: 16 }}
            >
                <CookieIcon />
            </Fab>
            <Modal
                open={isModalOpen}
                onClose={handleClose}
                aria-labelledby="cookies-modal-title"
                aria-describedby="cookies-modal-description"
                style={{ maxWidth: !isMobile ? '50%' : '', marginLeft: 'auto', marginRight: 'auto' }}
            >
                <Box sx={{ width: '80%', margin: 'auto', marginTop: '10%', bgcolor: 'background.paper', p: 4, borderRadius: 2, position: 'relative' }}>
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{ position: 'absolute', top: 8, right: 8 }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography id="cookies-modal-title" variant="h6" component="h2" marginBottom={2}>
                        Informacje o plikach cookie
                    </Typography>
                    <Stack spacing={2}>
                        {cookieCategories.map((item, index) => (
                            <Accordion key={index}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                    <Typography>{item.category}</Typography>
                                    {item.required && (
                                        <Typography variant="caption" color="green" sx={{ marginLeft: 'auto', marginRight: 2 }}>
                                            Zawsze aktywne
                                        </Typography>
                                    )}
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>{item.description}</Typography>
                                </AccordionDetails>
                            </Accordion>
                        ))}
                    </Stack>
                </Box>
            </Modal>
        </>
    );
};

export default Cookies;
