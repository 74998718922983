import React, { FC, useState } from 'react';
import { Container, Typography, Accordion, AccordionSummary, AccordionDetails, Stack } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

interface FAQItem {
  question: string;
  answer: string;
}

const faqItems: FAQItem[] = [
  {
    question: "Jak mogę zgłosić roszczenie?",
    answer: "Aby zgłosić roszczenie, wystarczy wypełnić nasz formularz online, podając podstawowe informacje o swoim locie.",
  },
  {
    question: "Jakie dokumenty są potrzebne do zgłoszenia roszczenia?",
    answer: "Potrzebujemy kopii biletu lotniczego oraz potwierdzenia rezerwacji. W niektórych przypadkach mogą być wymagane dodatkowe dokumenty.",
  },
  {
    question: "Ile czasu zajmuje proces uzyskania odszkodowania?",
    answer: "Czas uzyskania odszkodowania może się różnić w zależności od linii lotniczej i skomplikowania sprawy. Zazwyczaj proces trwa od kilku tygodni do kilku miesięcy.",
  },
];

const FAQPage: FC = () => {
  return (
    <Container>
      <Typography variant="h4" marginBottom={3}>FAQ</Typography>
      <Stack spacing={2}>
        {faqItems.map((item, index) => (
          <Accordion key={index}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>{item.question}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>{item.answer}</Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </Stack>
    </Container>
  );
};

export default FAQPage;
