import * as React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import ProTip from './ProTip';
import ClaimStepperForm from './components/ClaimStepperForm/ClaimStepperForm';
import { Route, Routes } from 'react-router-dom';
import { AppBar, Button, IconButton, Stack, Toolbar } from '@mui/material';
import CalculateRecompensate from './components/CalculateRecompensate/CalculateRecompensate';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import HomePage from './components/HomePage/HomePage';
import NavBar from './components/NavBar/NavBar';
import InformativePage from './components/InformativePage/InformativePage';
import { Provider } from 'react-redux'
import { store } from './app/store';
import { isMobile } from 'react-device-detect';
import Footer from './components/Footer/Footer';
import UploadDetails from './components/UploadDetails/UploadDetails';
import ContactPage from './components/ContactPage/ContactPage';
import { AppRoutes } from './utils/routes.const';
import AboutUs from './components/AboutUs/AboutUs';
import FAQPage from './components/FAQPage/FAQPage';
import Cookies from './components/Cookies/Cookies';

export default function App() {

  return (
    <>
      <Provider store={store}>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={navigator.language}>
          <NavBar />
          <Routes>
            <Route path={AppRoutes.Home} element={<HomePage />} />
            <Route path={AppRoutes.Claim} element={<ClaimStepperForm />} />
            <Route path={AppRoutes.Documents} element={<UploadDetails />} />
            <Route path={AppRoutes.Calculate} element={<CalculateRecompensate wrapperStyle={{ marginTop: isMobile ? 40 : 60 }} />} />
            <Route path={AppRoutes.Terms} element={<InformativePage title="Regulamin" filename={'terms_of_use.md'} />} />
            <Route path={AppRoutes.Privacy} element={<InformativePage title="Polityka Prywatności" filename={'privacy_policy.md'} />} />
            <Route path={AppRoutes.Contact} element={<ContactPage />} />
            <Route path={AppRoutes.AboutUs} element={<AboutUs />} />
            <Route path={AppRoutes.Faq} element={<FAQPage />} />
          </Routes>
          <Footer />
          <Cookies />
        </LocalizationProvider >
      </Provider>
    </>

  );
}
