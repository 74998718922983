import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import FlightInformationPart from '../FlightInformationPart/FlightInformationPart';
import IncidentDetails from '../IncidentDetails/IncidentDetails';
import { Divider, Stack } from '@mui/material';
import PersonalDetailsPart from '../PersonalDetailsPart/PersonalDetailsPart';
import UserSignature from '../UserSignature/UserSignature';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from '../../app/hooks'
import { isMobile } from 'react-device-detect';

const steps = ['Szczegóły zdarzenia', 'Szczegóły lotu', 'Szczegóły personalne', 'Podpis użytkownika'];


export default function ClaimStepperForm() {
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set<number>());
  const [prefilledValues, setPrefilledValues] = React.useState({ departure: '', arrival: '' });


  // TODO: use redux to store the values
  const { flightInformation, userSignature, personalDetails } = useAppSelector((state) => state.component)
  const dispatch = useAppDispatch()


  React.useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const departure = params.get('departure') || '';
    const arrival = params.get('arrival') || '';
    if (departure && arrival) {
      setPrefilledValues({ departure, arrival });
      setActiveStep(1);
    }
  }, [location]);

  const isStepOptional = (step: number) => {
    return false;
    // return step === 1;
  };

  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleFlightInformationSubmit = () => {
    // handle flight information submit logic here

  };

  const handleDelayDetailsSubmit = () => {
    // handle flight information submit logic here
  };

  const handlePersonalDetailsSubmit = () => {
    // handle flight information submit logic here
  };

  function dataURItoBlob(dataURI: string) {
    var byteString = atob(dataURI.split(',')[1]);
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: 'image/jpeg' });
  }

  const handleFormSubmit = async () => {

    var bodyFormData = new FormData();

    var flightData = JSON.parse(localStorage.getItem('flightData') || '');
    var personalDetails = JSON.parse(localStorage.getItem('personalDetails') || '');

    Object.entries(flightData).map(([key, value]: [string, any]) => bodyFormData.append(key, value));
    Object.entries(personalDetails).map(([key, value]: [string, any]) => bodyFormData.append(key, value));

    var image: string = JSON.parse(localStorage.getItem('userSignature') || '').userSignature;

    bodyFormData.append("userSignature", dataURItoBlob(image), 'usersignature.png');

    axios.post(`${process.env.REACT_APP_BACKEND_URL}/compensate`, bodyFormData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(({ data }) => {
        console.log(data);
      }
      ).catch(reason => {

      });


  }


  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <Stack sx={{ width: '70%' }} alignItems='center' style={{ marginTop: isMobile ? 40 : 60, marginBottom: '20%', marginLeft: 'auto', marginRight: 'auto' }}>
      <Stack>
        {activeStep != 3 && activeStep != 4 && <Typography variant="h4" component="h1" sx={{ mb: 2 }} textAlign={'center'}>
          Uzyskaj do 600 euro odszkodowania za opóźniony lub odwołany lot w 3 prostych krokach!
        </Typography>}

        <Stepper activeStep={activeStep} style={{ marginBottom: 40 }}>
          {steps.map((label, index) => {
            const stepProps: { completed?: boolean } = {};
            const labelProps: {
              optional?: React.ReactNode;
            } = {};
            if (isStepOptional(index)) {
              labelProps.optional = (
                <Typography variant="caption">Optional</Typography>
              );
            }
            if (isStepSkipped(index)) {
              stepProps.completed = false;
            }
            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}></StepLabel>
              </Step>
            );
          })}
        </Stepper>
      </Stack>

      <Stack alignItems='center'>
        {activeStep === steps.length ? (
          <React.Fragment>
            <Typography sx={{ mt: 2, mb: 1 }}>
              All steps completed - you&apos;re finished
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              <Box sx={{ flex: '1 1 auto' }} />
              <Button onClick={handleReset}>Reset</Button>
            </Box>
          </React.Fragment>
        ) : (
          <React.Fragment>

            {activeStep === 0 && (
              <IncidentDetails
                onSubmit={handleDelayDetailsSubmit}
                handleNext={handleNext}
                handleBack={handleBack}
                activeStep={activeStep}
                steps={steps} />)}
            {activeStep === 1 && (
              < FlightInformationPart
                onSubmit={handleFlightInformationSubmit}
                handleNext={handleNext}
                handleBack={handleBack}
                prefilledValues={prefilledValues}
                activeStep={activeStep}
                steps={steps} />)}
            {activeStep === 2 && (
              <PersonalDetailsPart
                onSubmit={handlePersonalDetailsSubmit} handleNext={handleNext}
                handleBack={handleBack}
                activeStep={activeStep}
                steps={steps} />)}
            {activeStep === 3 && (
              <UserSignature
                onSubmit={handleFormSubmit}
                handleNext={handleNext}
                handleBack={handleBack}
                activeStep={activeStep}
                steps={steps} />)}




          </React.Fragment>
        )}
      </Stack>
    </Stack >
  );
}