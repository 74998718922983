import React, { FC, useState } from 'react';
import { AppBar, Toolbar, IconButton, Typography, Button, Menu, MenuItem, Stack, Drawer, ListItem, List, ListItemText, Collapse } from '@mui/material';
import { isMobile } from 'react-device-detect';
import { Link, useNavigate } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import logo from '../../data/images/claim-flight-new-logo-text.png'
import DropdownMenu from '../DropdownMenu/DropdownMenu';
import { AppRoutes } from '../../utils/routes.const';

interface NavBarProps { }

const NavBar: FC<NavBarProps> = () => {

  const navigate = useNavigate();

  const policiesDropdownMenuItems = [
    { label: "Regulamin", onClick: () => navigate(AppRoutes.Terms) },
    { label: "Polityka prywatności", onClick: () => navigate(AppRoutes.Privacy) }
  ];

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isPoliciesOpen, setIsPoliciesOpen] = useState(false);

  return (
    <AppBar position="sticky" sx={{ bgcolor: "white" }} >
      <Toolbar>
        {!isMobile && <>
          <IconButton
            size="large"
            edge="start"
            color="secondary"
            aria-label="menu"
            sx={{ mr: 2, color: "black" }}
          >
          </IconButton>
          <Typography className="logo" onClick={() => navigate(AppRoutes.Home)} sx={{
            "&:hover": { cursor: 'pointer' },
          }}><img style={{ objectFit: 'contain', marginTop: 10 }} src={logo} alt='Logo' width='250px' height='80px' /></Typography>

          <Stack direction="row" spacing={2} sx={{ flexGrow: 1 }} justifyContent="flex-end">
            <DropdownMenu label="Polityki" items={policiesDropdownMenuItems} />
            <Button variant="text" onClick={() => navigate(AppRoutes.Claim)}>Formularz zgłoszeniony</Button>
            <Button variant="text" onClick={() => navigate(AppRoutes.Calculate)}>Sprawdz rekomensatę</Button>
            <Button variant="text" onClick={() => navigate(AppRoutes.AboutUs)}>O nas</Button>
            <Button variant="text" onClick={() => navigate(AppRoutes.Contact)}>Kontakt</Button>
            <Button variant="text" onClick={() => navigate(AppRoutes.Faq)}>Często zadawane pytania</Button>
          </Stack>

        </>
        }

        {isMobile && <>
          <IconButton
            size="large"
            edge="start"
            color="secondary"
            aria-label="menu"
            sx={{ mr: 2, color: "black" }}
            onClick={() => setIsDrawerOpen(true)}
          >
            <MenuIcon />
          </IconButton>
          <Stack style={{ marginLeft: '10%' }}>
            <Typography onClick={() => navigate(AppRoutes.Home)}
              sx={{ "&:hover": { cursor: 'pointer' } }}>
              <img style={{ objectFit: 'contain', marginTop: 10 }} src={logo} alt='Logo' width='150px' />
            </Typography>
          </Stack>

          <Drawer open={isDrawerOpen} onClose={() => setIsDrawerOpen(false)}>
            <List >
              <ListItem>
                <ListItemText primary="Strona główna" onClick={() => { navigate(AppRoutes.Home); setIsDrawerOpen(false); }} />
              </ListItem>

              <ListItem>
                <ListItemText primary="Oblicz rekompensatę" onClick={() => { navigate(AppRoutes.Claim); setIsDrawerOpen(false); }} />
              </ListItem>

              <ListItem>
                <ListItemText primary="Kontakt" onClick={() => { navigate(AppRoutes.Contact); setIsDrawerOpen(false); }} />
              </ListItem>
              
              <ListItem>
                <ListItemText primary="O nas" onClick={() => { navigate(AppRoutes.AboutUs); setIsDrawerOpen(false); }} />
              </ListItem>
              
              <ListItem>
                <ListItemText primary="Często zadawane pytania" onClick={() => { navigate(AppRoutes.Faq); setIsDrawerOpen(false); }} />
              </ListItem>

              <ListItem onClick={() => setIsPoliciesOpen(!isPoliciesOpen)}>
                <ListItemText primary="Polityki" />
                {isPoliciesOpen ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={isPoliciesOpen} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItem onClick={() => { navigate(AppRoutes.Terms); setIsDrawerOpen(false); }}>
                    <ListItemText primary="Regulamin" />
                  </ListItem>
                  <ListItem onClick={() => { navigate(AppRoutes.Privacy); setIsDrawerOpen(false); }}>
                    <ListItemText primary="Polityka prywatności" />
                  </ListItem>
                </List>
              </Collapse>

            </List>
          </Drawer></>
        }
      </Toolbar>
    </AppBar>
  );

}

export default NavBar;
