import React, { FC } from 'react';
import { Card, Container, Stack, Typography, useTheme } from '@mui/material';

interface UploadDetailsProps { }

const UploadDetails: FC<UploadDetailsProps> = () => {

  const theme = useTheme();
 const [prefilledValues, setPrefilledValues] = React.useState({ departure: '', arrival: '' });


  React.useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const departure = params.get('id') || '';
    
  }, [location]);


  return (
  <>
       <Container>
         <Stack>
 
         </Stack>
       </Container >
 
       <Container style={{ marginBottom: 100 }}>
 
         <Stack>
 
         </Stack>
       </Container>
 
     </>
  )
};

export default UploadDetails;
