import React, { FC } from 'react';
import styles from './PersonalDetailsPart.module.scss';
import { Box, Button, InputLabel, Stack, TextField } from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import { useForm, Controller } from 'react-hook-form';
import { DatePicker } from '@mui/x-date-pickers';
import { isMobile } from 'react-device-detect';
import { MuiTelInput } from 'mui-tel-input'

interface PersonalDetailsPartProps {
  onSubmit: (data: any) => void,
  handleNext(): void,
  handleBack(): void,
  activeStep: number,
  steps: string[],
}

interface PersonalDetails {
  name: string;
  surname: string;
  birthDate: Dayjs | null;
  email: string;
  phoneNumber: string;
}

const PersonalDetailsPart: FC<PersonalDetailsPartProps> = ({ handleBack, handleNext, activeStep, steps }) => {

  const { control, trigger, getValues, formState: { errors } } = useForm<PersonalDetails>({
    defaultValues: {
      name: '',
      surname: '',
      birthDate: null,
      email: '',
      phoneNumber: '',
    },

  });

  const handleValidation = async () => {
    const isValid = await trigger(["name","surname","birthDate", "email", "phoneNumber"])
    if (isValid) {
      const values = getValues()
      console.log(values)
      onSubmit();
      handleNext();
    }else{
        console.log("aaaa, chcialoby sie: ", errors)
      }
    }

  const onSubmit = async () => {
    localStorage.setItem('personalDetails', JSON.stringify(getValues()));
  };

  return (
    <Stack >
      <Stack marginBottom={3}>
        <InputLabel id="demo-simple-select-label">Imie</InputLabel>
        <Controller
          name='name'
          rules={{
            required: "Imie jest wymagane",
          }}
          control={control}
          render={({ field: { onChange, value, name } }) => {
            return (
              <TextField
                variant="outlined"
                value={value}
                onChange={onChange}
                error={!!errors.name}
                helperText ={errors.name ? errors.name.message : ""}
              />
            );
          }}
        />
      </Stack>
      <Stack marginBottom={3}>
        <InputLabel id="demo-simple-select-label">Nazwisko</InputLabel>
        <Controller
          name='surname'
          control={control}
          rules={{
            required: "Nazwisko jest wymagane",
          }}
          render={({ field: { onChange, value, name } }) => {
            return (
              <TextField
                variant="outlined"
                value={value}
                onChange={onChange}
                error={!!errors.surname}
                helperText ={errors.surname ? errors.surname.message : ""}
              />
            );
          }}
        />
      </Stack>

      <Stack marginBottom={3}>
        <InputLabel id="demo-simple-select-label">Data urodzenia</InputLabel>
        <Controller
          name='birthDate'
          control={control}
          rules={{
            required: "Data urodzenia jest wymagana",
            validate: (value) =>{
              const today = dayjs()
              const selectedDate = dayjs(value)
              const age = today.diff(selectedDate, 'year')
              return age >= 16 || "Musisz mieć co najmniej 16 lat.";
            },
          }}
          render={({ field: { onChange, value } }) => (
            <DatePicker value={value != dayjs(0) ? value : null} onChange={onChange} slotProps={{
              textField: {
                error: !!errors.birthDate,
                helperText: errors.birthDate ? errors.birthDate.message : ""
              }
            }}/>
          )}
        />
      </Stack>

      <Stack marginBottom={3}>
        <InputLabel id="demo-simple-select-label">E-mail</InputLabel>
        <Controller
          name='email'
          control={control}
          rules={{
            required: "E-mail jest wymagany",
            pattern: {
              value: /\S+@\S+\.\S+/,
              message: "Nieprawidłowy format E-mail",
            }
          }}
          render={({ field: { onChange, value, name } }) => {
            return (
              <TextField
                variant="outlined"
                value={value}
                onChange={onChange}
                error={!!errors.email}
                helperText ={errors.email ? errors.email.message : ""}
              />
            );
          }}
        />
      </Stack>


      <Stack marginBottom={3}>
        <InputLabel id="demo-simple-select-label">Numer telefonu</InputLabel>
        <Controller
          name='phoneNumber'
          control={control}
          rules={{
            required: "Numer telefonu jest wymagany",
          }}
          render={({ field: { onChange, value, name } }) => {
            return (
              <MuiTelInput value={value} onChange={onChange} defaultCountry="PL" error={!!errors.phoneNumber} helperText ={errors.phoneNumber ? errors.phoneNumber.message : ""}
              />
            );
          }}
        />
      </Stack>


      <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
        <Button
          variant="contained"
          color="inherit"
          disabled={activeStep === 0}
          onClick={handleBack}
          sx={{ mr: 1 }}
        >
          Back
        </Button>
        <Box sx={{ flex: '1 1 auto' }} />
        <Button
          variant="contained" onClick={() => {
            handleValidation()
          }}>
          {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
        </Button>
      </Box>

    </Stack>)
}
export default PersonalDetailsPart;
