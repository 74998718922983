import AboutUs from "../components/AboutUs/AboutUs";

export const AppRoutes = {
    Claim: 'odszkodowanie-za-lot',
    Calculate: 'kalkulator-odszkodowania',
    Documents: 'dokumenty',
    Terms: 'regulamin',
    Privacy: 'polityka-prywatnosci',
    Home: '/',
    AboutUs: 'o-nas',
    Contact: 'kontakt',
    Faq: 'pytania-i-odpowiedzi',
};