import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../store/store';

// Define a type for the slice state

interface FlightInformation {
    departure: string;
    arrival: string;
    flightNumber: string;
    euCountry: boolean;
    plannedArrival: string;
    actualArrival: string;
}

interface PersonalDetails {
    name: string;
    surname: string;
    birthDate: string;
    email: string;
    phoneNumber: string;
}

interface UserSignature {
    signature: string;
}

interface CompensationState {
    flightInformation: FlightInformation;
    personalDetails: PersonalDetails;
    userSignature: UserSignature;
}

// Define the initial state using that type
const initialState: CompensationState = {
    flightInformation: {
        departure: '',
        arrival: '',
        flightNumber: '',
        euCountry: false,
        plannedArrival: '',
        actualArrival: '',
    },
    personalDetails: {
        name: '',
        surname: '',
        birthDate: '',
        email: '',
        phoneNumber: '',
    },
    userSignature: {
        signature: '',
    },
}

export const compensationSlice = createSlice({
    name: 'compensation',
    initialState,
    reducers: {
       
        setFlightInformation: (state, action: PayloadAction<FlightInformation>) => {
            state.flightInformation = action.payload;
        },
        setPersonalDetails: (state, action: PayloadAction<PersonalDetails>) => {
            state.personalDetails = action.payload;
        },
        setUserSignature: (state, action: PayloadAction<UserSignature>) => {
            state.userSignature = action.payload;
        },
    },
})

export const { setFlightInformation, setPersonalDetails, setUserSignature } = compensationSlice.actions

// Other code such as selectors can use the imported `RootState` type
export const selectCompensation = (state: RootState) => state.component

export default compensationSlice.reducer